$(() => {
  $(window).on('click', function () {
    $('.dropdown-item ul:visible').hide();
  });

  $(document).on('click', '.dropdown-item', function(e) {
    var target = $(e.target),
        link = target.is('a') ? target : target.parents('.dropdown-header-link');

    if (!link.length) link = target;

    $('.dropdown-item').not(this).find('ul:visible').hide();

    if (link.parent().hasClass('dropdown-item')) {
      $(this).find('ul').toggle();
    } else {
      $(this).find('ul').hide();
    }

    if (target.parents('dropdown-item') || target.is('a') || !e.originalEvent) {
      e.stopPropagation();
    }
  });
});

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $(".mobile-menu").on('click', function(){
    $(".mobile-menu").toggleClass("active");
    $(".mobile-menu-nav").toggleClass("active");
  });

  $(".mobile-menu .sub-menu").on('click', function(){
    $(this).toggleClass("active");
  });

  $(window).on('scroll', function() {
    var scroll = $(window).scrollTop(),
        isShadowShown =  scroll >= 50 && $(window).width() > 1024;

    $(".application-menu-header .header-wrapper").toggleClass("box-shadow", isShadowShown);
  });

  function handleReadMoreTextBlock(){
    var screenWidth = $(window).width(),
        isFullTextShown = screenWidth > 1024 && screenWidth < 1350;

    $('.read-more-tablet').toggleClass('read-more', isFullTextShown);

    if (screenWidth < 1024) {
      $('.read-more-tablet-block').each(function() {
        $(this).next('.read-more-link').toggle(this.scrollHeight > this.clientHeight);
      });
    }
  }

  $(window).on('resize', handleReadMoreTextBlock);

  handleReadMoreTextBlock();

  $(".read-more, .read-more-tablet").on('click', function(){
    $(this).toggleClass("active");
  });

  $(".read-more-link").on('click', function(){
    $(this).prev('.read-more-tablet-block').toggleClass("active");
  });

  $(document).on('click',".stop-parent-click", function(event){
    event.stopPropagation();
  });

  $('a.current').parents('.dropdown-item').find('.dropdown-header-link').addClass('current');
});
